import { getMarketConfigFromLocale } from '@/config/market-configurations';

import { getPageNumberFromSlug, getSlugWithoutPageNumber } from './common';
import type { MarketAwareSlug } from './const';
import { getDetailsFromPathname } from './getDetailsFromPathName';

type Params =
    | {
          locale: string;
          slug?: string[];
          pathname?: never;
      }
    | { pathname: string; locale?: never; slug?: never };

export const getDetailsFromParams = (
    params: Params,
): { locale: string | undefined; slug: string[] | undefined } => {
    const { locale, slug } = params;

    if (params.pathname) {
        const details = getDetailsFromPathname(params.pathname);

        return details;
    }

    return { locale, slug };
};

export const parseParams = (params: Params): MarketAwareSlug => {
    const { locale, slug } = getDetailsFromParams(params);
    const marketConfig = getMarketConfigFromLocale(locale);

    if (!slug?.length) {
        return {
            marketConfig: marketConfig,
            slug: '/',
            cleanSlug: '/',
            parts: [''],
            pageNumber: 1,
        };
    }

    return {
        marketConfig: marketConfig,
        slug: decodeURI(`/${slug.join('/')}`),
        cleanSlug: decodeURI(`/${getSlugWithoutPageNumber(slug).join('/')}`),
        parts: getSlugWithoutPageNumber(slug).map(decodeURI),
        pageNumber: getPageNumberFromSlug(slug) ?? 1,
    };
};
