import type { HTMLProps } from 'react';
import React, { useMemo } from 'react';

import { cn } from '@/lib/utils';
import { formatPrice } from '@/utils/priceUtils';

const REGEXP_TRAILING_ZEROS = /^0+/;

const removeLeadingZero = (str: string) =>
    str.length > 1 ? str.replace(REGEXP_TRAILING_ZEROS, '') : str;

type Props<T> = T & {
    value: number;
    locale: string;
    suffix?: string | null;
    alignLabel?: 'left' | 'right';
};

export default function Number(props: Props<HTMLProps<HTMLInputElement>>) {
    const {
        id,
        max,
        min,
        name,
        label,
        suffix,
        locale,
        className,
        alignLabel = 'left',
        value,
    } = props;

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        const newValue = removeLeadingZero(event.target.value);        
        event.target.value = newValue;
    };

    const displayValue = useMemo(() => {
        if (!suffix) return value;
        if (suffix === '%CURRENCY%') return formatPrice(value, locale);
        return `${value} ${suffix}`;
    }, [value, suffix, locale]);

    return (
        <>
            <label
                htmlFor={id}
                className={cn(
                    'w-full',
                    'text-xxs',
                    alignLabel === 'left' ? 'text-left' : 'text-right',
                )}>
                {label}
            </label>
            <div
                className={cn(
                    'relative flex flex-col items-stretch justify-center transition-all ease-out',
                )}>
                <input
                    defaultValue={value}
                    name={name}
                    id={id}
                    type="number"
                    className={cn(
                        'peer block w-full p-1 placeholder-black',
                        'text-center text-sm',
                        'border border-black',
                        className,
                    )}
                    onChange={handleInputChange}
                    min={Math.ceil(min as number)}
                    max={Math.ceil(max as number)}
                />
                <div
                    className={cn(
                        'pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap bg-white text-sm transition-all peer-focus-within:hidden',
                    )}>
                    {displayValue}
                </div>
                <input
                    size={String(displayValue).length}
                    className="invisible block h-0 px-1 text-sm"
                    defaultValue={displayValue}
                />
            </div>
        </>
    );
}
