'use client';

import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { NextSSRInMemoryCache } from '@apollo/experimental-nextjs-app-support/ssr';
import ReactQueryClientProvider from '@app/_components/ReactQueryClientProvider/ReactQueryClientProvider';
import LoginDialog from '@components/Auth/LoginDialog';
import { MarketSelector } from '@components/MarketSelector';
import { AuthProvider } from '@context/authContext';
import { CartProvider } from '@context/cartContext';
import { SiteInfoProvider } from '@context/siteInfoContext';
import { WishlistProvider } from '@context/wishlistContext';
import { datadogRum } from '@datadog/browser-rum';
import type {
    RootLayoutQuery,
    RootLayoutSiteinfoFragment,
} from '@server/gql/graphql';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { deleteCookie } from 'cookies-next';
import { useReportWebVitals } from 'next/web-vitals';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { Dictionary } from 'types';

import { CART_COOKIE } from '@/config/cart';
import { envConfig } from '@/config/env';
import { useCurrentPathInitializer } from '@/stores/currentPathStore';
import { createServiceAuthToken } from '@/utils/api/createServiceAuthToken';
import { enableClientDiagnostics } from '@/utils/diagnostics/enableClientDiagnostics';

// regex patterns to identify known bot instances:
const botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|Screaming Frog SEO Spider|HeadlessChrome)';

const regex = new RegExp(botPattern, 'i');

// define var conditionalSampleRate as 0 if the userAgent matches a pattern in botPatterns
// otherwise, define conditionalSampleRate as 100
if (typeof window !== 'undefined') {
    // for backward compatibility, fall back to full sample rate
    const fallbackSampleRate = 100;
    let parsedMaxConditionalSampleRate = parseInt(
        envConfig.NEXT_PUBLIC_DATADOG_SAMPLE_RATE as string,
    );
    // unless an integer and completely in range (0 to 100 inclusive), use fallback
    if (
        isNaN(parsedMaxConditionalSampleRate) ||
        !isFinite(parsedMaxConditionalSampleRate) ||
        parsedMaxConditionalSampleRate < 0 ||
        parsedMaxConditionalSampleRate > 100
    ) {
        parsedMaxConditionalSampleRate = fallbackSampleRate;
    }

    const conditionalSampleRate = regex.test(navigator.userAgent)
        ? 0
        : parsedMaxConditionalSampleRate;

    datadogRum.init({
        applicationId: envConfig.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
        clientToken: envConfig.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
        site: envConfig.NEXT_PUBLIC_DATADOG_SITE as string,
        env: envConfig.NEXT_PUBLIC_DATADOG_ENV as string,
        version: process.env.NEXT_PUBLIC_APP_VERSION as string,
        service: 'no-ga.com',
        allowedTracingUrls: [
            (url) =>
                url.startsWith(
                    envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT as string,
                ),
        ],
        sessionSampleRate: conditionalSampleRate,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
    // datadogRum.startSessionReplayRecording();
}

const errorLink = onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach((error) => {
        if (error?.message === 'Not Found') {
            deleteCookie(CART_COOKIE);
            return;
        }
        switch (error?.extensions?.code) {
            case 'CART_NOT_EDITABLE':
                deleteCookie(CART_COOKIE);
                break;
            default:
                console.error(
                    'Failed to handle error code from GraphQL backend: ',
                    error?.extensions?.code,
                );
                break;
        }
    });
});

const httpLink = new HttpLink({
    uri: `${envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT}/${envConfig.NEXT_PUBLIC_SERVICE_ENDPOINT_PATH}`,
    headers: {
        Authorization: createServiceAuthToken(),
        'x-api-brand': 'noga',
    },
});

export const client = new ApolloClient({
    cache: new NextSSRInMemoryCache({
        typePolicies: {
            CartItem: {
                keyFields: ['id', 'lineNo'],
            },
        },
    }),
    link: ApolloLink.from([errorLink, httpLink]),
});

type Props = {
    dictionary: Dictionary;
    language: string;
    locale: string | undefined;
    children: ReactNode;
    siteinfo?: RootLayoutSiteinfoFragment | null;
    campaigns: RootLayoutQuery['campaigns'];
    wishlist: RootLayoutQuery['wishlist'];
};

export default function Provider(props: Props) {
    enableClientDiagnostics();
    useCurrentPathInitializer();

    const { dictionary, locale, children, siteinfo, campaigns, wishlist } =
        props;
    const [apolloClient] = useState(client);

    if (envConfig.NEXT_PUBLIC_DATADOG_ENV === 'dev') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useReportWebVitals((metric) => {
            /* eslint-disable-next-line no-console */
            console.log(metric);
        });
    }

    return (
        <ReactQueryClientProvider>
            <ApolloProvider client={apolloClient}>
                <SiteInfoProvider
                    siteinfo={siteinfo}
                    campaigns={campaigns}
                    wishlist={wishlist}>
                    <AuthProvider locale={locale}>
                        <CartProvider dictionary={dictionary}>
                            <MarketSelector dictionary={dictionary} />
                            <LoginDialog />
                            <WishlistProvider>{children}</WishlistProvider>
                        </CartProvider>
                    </AuthProvider>
                </SiteInfoProvider>
            </ApolloProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </ReactQueryClientProvider>
    );
}
