import { graphql } from '@server/gql';

export const getWalleyCheckoutFormQuery = graphql(/* GraphQL */ `
    query getWalleyCheckoutForm(
        $cartId: String!
        $marketCode: String!
        $brandCode: String!
        $type: String!
        $shopCallbackUrl: String!
        $shopTermsUrl: String!
        $newSession: Boolean
    ) {
        walleyCheckoutForm(
            cartId: $cartId
            marketCode: $marketCode
            brandCode: $brandCode
            type: $type
            shopCallbackUrl: $shopCallbackUrl
            shopTermsUrl: $shopTermsUrl
            newSession: $newSession
        ) {
            status
            cartId
            formHtml
            orderNumber
        }
    }
`);
