import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import parseVariant from '@server/utils/parseLoop/parseVariant';
import type {
    Loop54Payload,
    Loop54Response,
} from '@server/utils/parseLoop/types';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import type { MarketConfiguration } from '@/config/market-configurations';
import { setLoopCacheOptions } from '@/utils/cache/setLoopCacheOptions';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

const CHUNK_SIZE = 45;
const chunkArray = (arr: string[], size: number): string[][] =>
    arr.length > size
        ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
        : [arr];

const fetchAccessories = cache(
    async (
        productAccessories: string[],
        variantAccessories: string[],
        marketConfig: MarketConfiguration,
    ) => {
        const url = `${marketConfig.searchEngineUrl}/${PRODUCTS_ENDPOINT}`;
        const chunks = chunkArray(
            [...productAccessories, ...variantAccessories],
            CHUNK_SIZE,
        );

        if (!chunks.flat().length) return [];

        async function main(chunk: string[]) {
            const payload: Loop54Payload = {
                customData: { market: marketConfig.loop54Market },
                resultsOptions: {
                    skip: 0,
                    take: 45,
                    facets: [],
                    filter: {
                        and: [
                            {
                                type: 'type',
                                value: 'Variant',
                            },
                            {
                                or: chunk.map((value) => ({
                                    type: 'id',
                                    value,
                                })),
                            },
                            {
                                attributeName: `${marketConfig.loop54Market}_Price`,
                            },
                        ],
                    },
                },
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Api-Version': 'V3',
                    'User-Id': getSearchEngineUser(),
                },
                body: JSON.stringify(payload),
                ...setLoopCacheOptions({
                    revalidate: ONCE_PER_EIGHT_HOURS,
                    tags: [
                        REVALIDATION_TAGS.loopAll,
                        ...chunk.map(REVALIDATION_TAGS.productById),
                    ],
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        }

        const response: Loop54Response[] = await Promise.all(chunks.map(main));

        return response
            .flatMap((res) => res.results.items)
            .map(parseVariant)
            .flat();
    },
);

export const preload = (
    productAccessories: string[],
    variantAccessories: string[],
    marketConfig: MarketConfiguration,
) => {
    void fetchAccessories(productAccessories, variantAccessories, marketConfig);
};

export default fetchAccessories;
