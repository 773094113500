import type {
    GetAnonymousWishlistQuery,
    GetSharedWishlistTagQuery,
    GetWishlistByTagQuery,
    GetWishlistQuery,
    WishlistItemDto,
    WishlistItemWithTagsDto,
} from '@server/gql/graphql';
import { PRODUCTS_ENDPOINT } from '@server/utils/parseLoop/const';
import { parseWishlist } from '@server/utils/parseLoop/parseWishlist';
import type {
    Filter,
    Loop54Response,
    Loop54Sortby,
} from '@server/utils/parseLoop/types';
import { cache } from 'react';
import type { Product, Variant } from 'types/product';

import type { MarketConfiguration } from '@/config/market-configurations';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

export type Props = {
    skip?: number;
    take?: number;
    sort?: [Loop54Sortby];
    marketConfig: MarketConfiguration;
};

function findWishlistItems(
    response:
        | GetWishlistQuery['getWishlist']
        | GetWishlistByTagQuery['getWishlistByTag']
        | GetSharedWishlistTagQuery['getSharedWishlistTag']
        | GetAnonymousWishlistQuery['getAnonymousWishlist'],
) {
    switch (response.__typename) {
        case 'WishlistResponse':
        case 'AnonymousWishlistResponse':
        case 'SharedWishlistTagResponse':
            return response.data?.wishlistItems;
        case 'WishlistItemResponse':
            return response.data;
        default:
            return undefined;
    }
}

function generateLoopFilter(
    response:
        | GetWishlistQuery['getWishlist']
        | GetWishlistByTagQuery['getWishlistByTag']
        | GetSharedWishlistTagQuery['getSharedWishlistTag']
        | GetAnonymousWishlistQuery['getAnonymousWishlist'],
) {
    if (!response) return { or: [] };

    const items = findWishlistItems(response);

    if (!items?.length) return { or: [] };

    const filters = items.reduce<{ and: Filter[] }[]>((a, b) => {
        if (!b) return a;
        const isVariant = Boolean(b.variantId);
        if (isVariant) {
            return [
                ...a,
                {
                    and: [
                        {
                            type: 'id',
                            value: b.variantId as string,
                        },
                        {
                            type: 'type',
                            value: 'Variant',
                        },
                    ],
                },
            ];
        }
        return [
            ...a,
            {
                and: [
                    {
                        type: 'id',
                        value: b.productId as string,
                    },
                    {
                        type: 'type',
                        value: 'Product',
                    },
                ],
            },
        ];
    }, []);
    return { or: filters.slice(0, 16) };
}

function mapResponse(
    wishlist:
        | GetWishlistQuery['getWishlist']
        | GetWishlistByTagQuery['getWishlistByTag']
        | GetAnonymousWishlistQuery['getAnonymousWishlist'],
    response: Loop54Response,
): ((Product | Variant) & {
    wishlistItem?: WishlistItemDto | WishlistItemWithTagsDto | null;
})[] {
    let items: (WishlistItemDto | WishlistItemWithTagsDto | null)[] = [];
    if (!response) return [];
    if (wishlist.__typename === 'WishlistResponse') {
        items = wishlist.data?.wishlistItems ?? [];
    } else if (wishlist.__typename === 'WishlistItemResponse') {
        items = wishlist.data ?? [];
    } else if (wishlist.__typename === 'AnonymousWishlistResponse') {
        items = wishlist.data?.wishlistItems ?? [];
    }

    const products = parseWishlist(response);

    return products.map((product) => {
        const wishlistItem = items.find((item) =>
            [item?.productId, item?.variantId].includes(product.id),
        );
        return { ...product, wishlistItem };
    });
}

const fetchWishlist = cache(
    async (
        wishlistData:
            | GetWishlistQuery['getWishlist']
            | GetWishlistByTagQuery['getWishlistByTag']
            | GetSharedWishlistTagQuery['getSharedWishlistTag']
            | GetAnonymousWishlistQuery['getAnonymousWishlist'],
        props: Props,
    ): Promise<((Product | Variant) & { tag?: number[] })[]> => {
        const filter = generateLoopFilter(wishlistData);
        const { take = 16, skip = 0, sort: sortBy, marketConfig } = props;

        const payload = {
            customData: { market: marketConfig.loop54Market },
            resultsOptions: {
                take,
                skip,
                sortBy,
                facets: [],
                filter,
            },
        };

        if (!filter?.or.length) return [];

        const response = await fetch(
            `${marketConfig.searchEngineUrl}/${PRODUCTS_ENDPOINT}`,
            {
                method: 'POST',
                headers: {
                    'Api-Version': 'V3',
                    'User-Id': getSearchEngineUser(),
                },
                body: JSON.stringify(payload),
                cache: 'no-store',
            },
        );
        const data: Loop54Response = await response.json();
        if (!data) return [];
        if (
            wishlistData.__typename === 'WishlistItemResponse' ||
            wishlistData.__typename === 'WishlistResponse' ||
            wishlistData.__typename === 'AnonymousWishlistResponse'
        ) {
            const products = mapResponse(wishlistData, data);
            return products;
        }
        return parseWishlist(data);
    },
);

export default fetchWishlist;
