import type { MutableRefObject, ReactNode } from 'react';
import { createContext } from 'react';
import type { Dictionary } from 'types';

import type { MarketAwareSlug } from '@/utils/const';

import type { useCheckout } from './Checkout.hooks';

type Checkout = ReturnType<typeof useCheckout>;

type CheckoutContextProps = {
    dictionary: Dictionary;
    details: MarketAwareSlug;
    isEmpty?: boolean;
    checkout?: Checkout;
    adyenDropInRef?: MutableRefObject<HTMLDivElement | null>;
};

export type CheckoutProviderProps = CheckoutContextProps & {
    children: ReactNode;
};

export const CheckoutContext = createContext<CheckoutContextProps | undefined>(
    undefined,
);
