import { PRODUCTS_BY_CATEGORY_ENDPOINT } from '@server/parsers/loop54';
import { KEYS } from '@server/utils/parseLoop/const';
import type { Loop54Response } from '@server/utils/parseLoop/types';
import { cache } from 'react';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import type { MarketConfiguration } from '@/config/market-configurations';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

const fetchSiblings = cache(
    async (
        primary: string,
        marketConfig: MarketConfiguration,
        type: 'brand' | 'designer',
        category?: string | null,
    ) => {
        const url = `${marketConfig.searchEngineUrl}/${PRODUCTS_BY_CATEGORY_ENDPOINT}`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': getSearchEngineUser(),
            },
            body: JSON.stringify({
                customData: { market: marketConfig.loop54Market },
                attribute: {
                    name:
                        type === 'brand'
                            ? KEYS.BRAND_CODE_KEY
                            : KEYS.DESIGNER_CODE_KEY,
                    value: primary,
                },
                resultsOptions: {
                    facets: [
                        {
                            attributeName: KEYS.CATEGORY_CODE_KEY,
                        },
                    ],
                    skip: 0,
                    take: 0,
                    ...(category && {
                        filter: {
                            and: [
                                {
                                    type: 'attribute',
                                    attributeName: KEYS.CATEGORY_CODE_KEY,
                                    value: category,
                                },
                                {
                                    attributeName: `${marketConfig.loop54Market}_Price`,
                                },
                            ],
                        },
                    }),
                },
            }),
            next: {
                tags: [
                    REVALIDATION_TAGS.loopAll,
                    type === 'brand'
                        ? REVALIDATION_TAGS.brandByCode(primary)
                        : REVALIDATION_TAGS.designerByCode(primary),
                    category && REVALIDATION_TAGS.categoryByCode(category),
                ].filter(Boolean) as string[],
                revalidate: ONCE_PER_EIGHT_HOURS,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const items: Loop54Response = await response.json();

        const facet = items.results?.facets?.at(0);

        const categories = facet?.items?.map((f) => f.item);

        return categories ?? [];
    },
);

export const preload = (
    brand: string,
    market: MarketConfiguration,
    type: 'brand' | 'designer',
    category?: string | null,
) => {
    void fetchSiblings(brand, market, type, category);
};

export default fetchSiblings;
