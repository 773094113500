import { envConfig } from './env';
import { FEATURE_FLAGS } from './featureFlags';
import type { MarketConfiguration } from './market-configurations';

export function isAdyenCheckoutEnabled({
    marketConfig,
}: {
    marketConfig: MarketConfiguration;
}) {
    return marketConfig.adyenCheckoutEnabled && FEATURE_FLAGS.checkout.adyen;
}

export function isWalleyCheckoutEnabled({
    marketConfig,
}: {
    marketConfig: MarketConfiguration;
}) {
    return marketConfig.walleyCheckoutEnabled && FEATURE_FLAGS.checkout.walley;
}

export const GIFTCARD_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_GIFT_CARD_PAYMENT_METHOD_ID,
);

export const KLARNA_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_KLARNA_PAYMENT_METHOD_ID,
);

export const ADYEN_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_ADYEN_PAYMENT_METHOD_ID,
);

export const WALLEY_PAYMENT_METHOD_ID = Number(
    envConfig.NEXT_PUBLIC_WALLEY_PAYMENT_METHOD_ID,
);
