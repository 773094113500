import type { Dictionary } from 'types';
import { z } from 'zod';

import type { MarketCode } from '@/config/market-configurations';
import { getZipCodeRegexByMarketCode } from '@/config/market-configurations';

export function getZipCodeOnlySchema({
    marketCode,
    dictionary,
}: {
    marketCode: MarketCode;
    dictionary: Dictionary;
}) {
    const zipCodeRegex = getZipCodeRegexByMarketCode(marketCode);

    return z.object({
        zipCode: z
            .string()
            .min(1, dictionary.zipCodeRequired ?? 'Zip code is required')
            .regex(
                zipCodeRegex,
                dictionary.zipCodeInvalid ?? 'Invalid zip code',
            ),
    });
}

export function getAddressSchema({
    marketCode,
    dictionary,
}: {
    marketCode: MarketCode;
    dictionary: Dictionary;
}) {
    const zipCodeRegex = getZipCodeRegexByMarketCode(marketCode);

    return z.object({
        firstName: z
            .string()
            .min(1, dictionary.firstNameRequired ?? 'First name is required'),
        lastName: z.string().min(1, 'Last name is required'),
        email: z
            .string()
            .min(1, dictionary.emailRequired ?? 'Email is required')
            .email(dictionary.emailInvalid ?? 'Invalid email address'),
        company: z.string().optional(),
        phoneNumber: z
            .string()
            .min(
                1,
                dictionary.phoneNumberRequired ?? 'Phone number is required',
            ),
        line1: z
            .string()
            .min(1, dictionary.streetRequired ?? 'Street address is required'),
        line2: z.string().optional(),
        city: z.string().min(1, dictionary.cityRequired ?? 'City is required'),
        zipCode: z
            .string()
            .min(1, dictionary.zipCodeRequired ?? 'Zip code is required')
            .regex(
                zipCodeRegex,
                dictionary.zipCodeInvalid ?? 'Invalid zip code',
            ),
        country: z.string().optional(),
    });
}

export function getDeliveryAddressSchema({
    marketCode,
    dictionary,
}: {
    marketCode: MarketCode;
    dictionary: Dictionary;
}) {
    const zipCodeRegex = getZipCodeRegexByMarketCode(marketCode);

    return z.object({
        deliveryFirstName: z
            .string()
            .min(1, dictionary.firstNameRequired ?? 'First name is required'),
        deliveryLastName: z
            .string()
            .min(1, dictionary.lastNameRequired ?? 'Last name is required'),
        deliveryLine1: z
            .string()
            .min(1, dictionary.streetRequired ?? 'Street address is required'),
        deliveryCity: z
            .string()
            .min(1, dictionary.cityRequired ?? 'City is required'),
        deliveryZipCode: z
            .string()
            .min(1, dictionary.zipCodeRequired ?? 'Zip code is required')
            .regex(
                zipCodeRegex,
                dictionary.zipCodeInvalid ?? 'Invalid zip code',
            ),
        deliveryCountry: z.string().optional(),
    });
}

export const nShiftDeliveryOptionSchema = z.object({
    valid: z.boolean(),
    deliveryCheckoutId: z.string(),
    selectedOptionId: z.string(),
    optionIds: z.array(z.string()),
    price: z.number(),
    agent: z.string().optional(),
    selectedAddons: z.array(z.unknown()),
    fields: z.array(z.unknown()),
    taxRate: z.number(),
});
