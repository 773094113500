import dotenv from 'dotenv';
import { z } from 'zod';

if (!process.env.NEXT_PUBLIC_ENVIRONMENT && typeof window === 'undefined') {
    const dotEnvConfig = dotenv.config();

    if (dotEnvConfig.error) {
        throw new Error('Could not load environment variables');
    }
}

const VALID_ENVIRONMENTS = [
    'local',
    'dev',
    'stage',
    'preprod',
    'prod',
] as const;
const EnvEnum = z.enum(VALID_ENVIRONMENTS);
const environment = EnvEnum.parse(process.env.NEXT_PUBLIC_ENVIRONMENT);
const VALID_APP_MARKETS = ['se', 'no', 'de'] as const;
const MarketEnum = z.enum(VALID_APP_MARKETS);
const site = MarketEnum.parse(
    process.env.NEXT_PUBLIC_APP_MARKET?.toLowerCase(),
);

/////////////////////////// CONTENTFUL ///////////////////////////
type ContentfulConfig = {
    CONTENTFUL_SPACE_ID: string;
    CONTENTFUL_BRAND_PAGE_TEXT_BLOCK_NAME: string;
    CONTENTFUL_MAGAZINE_DICTIONARY_ID: string;
    CONTENTFUL_CAMPAIGN_DICTIONARY_ID: string;
    CONTENTFUL_CATEGORY_PAGE_DICTIONARY_ID: string;
    CONTENTFUL_GLOBAL_DICTIONARY_ID: string;
    CONTENTFUL_SPACE_ENVIRONMENT: string;
};

const contentfulConfigCommon = {
    CONTENTFUL_SPACE_ID: 'itxkjaj41rzb',
    CONTENTFUL_BRAND_PAGE_TEXT_BLOCK_NAME: 'Info box brand pages',
    CONTENTFUL_MAGAZINE_DICTIONARY_ID: '5eakaDD86uoEfYdS9TMzWk',
    CONTENTFUL_CAMPAIGN_DICTIONARY_ID: '3teOmb3bEKVej4IwM3mxhc',
    CONTENTFUL_GLOBAL_DICTIONARY_ID: 'AUW4IT51WnUWLjIu1y2XR',
    CONTENTFUL_CATEGORY_PAGE_DICTIONARY_ID: '26pPduK6BYLwGYoCshTMlt',
};

const contentfulSpaceEnvironment = getContentfulSpaceEnvironment();

function getContentfulSpaceEnvironment() {
    // Use the process.env.CONTENTFUL_SPACE_ENVIRONMENT for testing and local development purposes only.
    // If not set use the env determined by the environment.
    const { data: contentfulSpaceEnvironment } = z
        .string()
        .safeParse(process.env.CONTENTFUL_SPACE_ENVIRONMENT);

    if (contentfulSpaceEnvironment) {
        return contentfulSpaceEnvironment;
    }

    switch (environment) {
        case EnvEnum.enum.local:
        case EnvEnum.enum.dev:
        case EnvEnum.enum.stage:
            return 'stage';
        case EnvEnum.enum.preprod:
        case EnvEnum.enum.prod:
        default:
            return 'master';
    }
}

const contentfulConfig: ContentfulConfig = {
    ...contentfulConfigCommon,
    CONTENTFUL_SPACE_ENVIRONMENT: contentfulSpaceEnvironment,
};

/////////////////////////// DATADOG ///////////////////////////
type DatadogConfig = {
    NEXT_PUBLIC_DATADOG_SITE: string;
    NEXT_PUBLIC_DATADOG_ENV: string;
    NEXT_PUBLIC_DATADOG_SAMPLE_RATE: string;
    NEXT_PUBLIC_DATADOG_APPLICATION_ID: string;
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: string;
};

let datadogEnvironment: string;
let sampleRate: string;
switch (environment) {
    case EnvEnum.enum.local:
        datadogEnvironment = 'local';
        sampleRate = '100';
        break;
    case EnvEnum.enum.stage:
        datadogEnvironment = 'dev';
        sampleRate = '100';
        break;
    case EnvEnum.enum.preprod:
        datadogEnvironment = 'pre-prod';
        sampleRate = '100';
        break;
    case EnvEnum.enum.prod:
    default:
        datadogEnvironment = 'prod';
        sampleRate = '1';
        break;
}

let applicationId: string;
switch (site) {
    case MarketEnum.enum.se:
        applicationId = 'e62ac317-bbe1-4ecc-b0b5-c7037044fe34';
        break;
    case MarketEnum.enum.no:
        applicationId = '3d509390-197e-403c-b4ee-916edfbc3497';
        break;
    case MarketEnum.enum.de:
    default:
        applicationId = '1c045c58-93da-4b14-9a6c-aa434c011294';
        break;
}

let datadogClientToken: string;
switch (site) {
    case MarketEnum.enum.se:
        datadogClientToken = 'puba6a31986623e12840c912c5f3adaf9d1';
        break;
    case MarketEnum.enum.no:
        datadogClientToken = 'pub688ebe5b4ba07acec6f429e5316f305e';
        break;
    case MarketEnum.enum.de:
    default:
        datadogClientToken = 'pubedbd93c8cdafb11d73eb0d796808efb1';
}

const datadogConfig: DatadogConfig = {
    NEXT_PUBLIC_DATADOG_SITE: 'datadoghq.eu',
    NEXT_PUBLIC_DATADOG_ENV: datadogEnvironment,
    NEXT_PUBLIC_DATADOG_SAMPLE_RATE: sampleRate,
    NEXT_PUBLIC_DATADOG_APPLICATION_ID: applicationId,
    NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: datadogClientToken,
};

/////////////////////////// FEATURE FLAGS ///////////////////////////
type FeatureFlagConfig = {
    NEXT_PUBLIC_FEATURES_SEARCH_ENGINE_EXPLODE_PRODUCTS: string;
    NEXT_PUBLIC_REVIEWS: string;
    NEXT_PUBLIC_FEATURES_CACHE_LOOP: string;
    NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_IMBOX_BUTTON: string;
    NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_EMAIL_LINK: string;
    NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_PHONE_LINK: string;
    NEXT_PUBLIC_FEATURES_PRODUCTS_SWATCHES: string;
    NEXT_PUBLIC_FEATURES_PRODUCTS_VIDEOS: string;
    NEXT_PUBLIC_FEATURES_SHOW_DISCOUNT_POPUP: string;
    NEXT_PUBLIC_FEATURES_CHECKOUT_GIFTCARDS: string;
    NEXT_PUBLIC_FEATURES_STATIC_PRODUCT_PAGES: string;
    NEXT_PUBLIC_FEATURES_EXPONENTIAL_PRICE_FILTER: string;
    REDIRECT_ENGINE_ENABLED: string;
    NEXT_PUBLIC_NORDICS_FEATURE_FLAG: string;
    NEXT_PUBLIC_FEATURES_CHECKOUT_GIFTCARDS_CHANGE_BUTTON: string;
    NEXT_PUBLIC_FEATURES_CHECKOUT_ADYEN: string;
    NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY: string;
};

function isGiftCardFeatureFlagEnabled() {
    switch (environment) {
        case EnvEnum.enum.local:
        case EnvEnum.enum.dev:
        case EnvEnum.enum.stage:
            return 'true';
        case EnvEnum.enum.preprod:
        case EnvEnum.enum.prod:
        default:
            return 'false';
    }
}

function isAdyenCheckoutFeatureFlagEnabled() {
    switch (site) {
        case MarketEnum.enum.se:
        case MarketEnum.enum.no:
            return 'false';
        case MarketEnum.enum.de:
        default:
            const { data: adyenFeatureFlag } = z
                .string()
                .safeParse(process.env.NEXT_PUBLIC_FEATURES_CHECKOUT_ADYEN);

            if (adyenFeatureFlag) {
                return adyenFeatureFlag;
            }

            return 'true';
    }
}

function isWalleyCheckoutFeatureFlagEnabled() {
    switch (site) {
        case MarketEnum.enum.se:
        case MarketEnum.enum.de:
        case MarketEnum.enum.no:
            const { data: walleyFeatureFlag } = z
                .string()
                .safeParse(process.env.NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY);

            if (walleyFeatureFlag) {
                return walleyFeatureFlag;
            }

            return 'true';
        default:
            return 'false';
    }
}

const featureFlagConfig: FeatureFlagConfig = {
    NEXT_PUBLIC_FEATURES_SEARCH_ENGINE_EXPLODE_PRODUCTS:
        environment === 'local' || environment === 'dev' ? 'false' : 'true',
    NEXT_PUBLIC_REVIEWS: 'true',
    NEXT_PUBLIC_FEATURES_CACHE_LOOP: 'true',
    NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_IMBOX_BUTTON: 'true',
    NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_EMAIL_LINK: 'true',
    NEXT_PUBLIC_FEATURES_CUSTOMER_SERVICE_PHONE_LINK: 'true',
    NEXT_PUBLIC_FEATURES_PRODUCTS_SWATCHES: 'false',
    NEXT_PUBLIC_FEATURES_PRODUCTS_VIDEOS: 'true',
    NEXT_PUBLIC_FEATURES_SHOW_DISCOUNT_POPUP: 'false',
    NEXT_PUBLIC_FEATURES_CHECKOUT_GIFTCARDS: isGiftCardFeatureFlagEnabled(),
    NEXT_PUBLIC_FEATURES_STATIC_PRODUCT_PAGES:
        process.env.NEXT_PUBLIC_FEATURES_STATIC_PRODUCT_PAGES ?? 'false',
    NEXT_PUBLIC_FEATURES_EXPONENTIAL_PRICE_FILTER:
        process.env.NEXT_PUBLIC_FEATURES_EXPONENTIAL_PRICE_FILTER ?? 'false',
    REDIRECT_ENGINE_ENABLED: 'true',
    NEXT_PUBLIC_NORDICS_FEATURE_FLAG: 'true',
    NEXT_PUBLIC_FEATURES_CHECKOUT_GIFTCARDS_CHANGE_BUTTON: 'false',
    NEXT_PUBLIC_FEATURES_CHECKOUT_ADYEN: isAdyenCheckoutFeatureFlagEnabled(),
    NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY: isWalleyCheckoutFeatureFlagEnabled(),
};

/////////////////////////// CACHE ///////////////////////////
type CacheConfig = {
    USE_REDIS_CACHE: string;
    REVALIDATION_APP_URL: string;
};

const useRedisCache = z.string().parse(process.env.USE_REDIS_CACHE ?? 'false');
const cacheConfig: CacheConfig = {
    USE_REDIS_CACHE: useRedisCache,
    REVALIDATION_APP_URL:
        process.env.REVALIDATION_APP_URL ??
        'http://cb1bf16e-f858-4cb9-8d91-25f4a2998927.k8s.civo.com',
};

/////////////////////////// GRAPH ///////////////////////////
type GraphConfig = {
    NEXT_PUBLIC_SERVICE_ENDPOINT_PATH: string;
    NEXT_PUBLIC_SERVICE_USER: string;
    GRAPHQL_AUTH_USER_V2: string;
    NOGA_API_ENDPOINT: string;
    NGLM_GRAPH_ENDPOINT: string;
    NEXT_PUBLIC_SERVICE_ENDPOINT: string;
};

const graphConfigCommon = {
    NEXT_PUBLIC_SERVICE_ENDPOINT_PATH: 'graphql',
    NEXT_PUBLIC_SERVICE_USER: 'nglm-graph',
};

const graphConfigLocal: GraphConfig = {
    ...graphConfigCommon,
    GRAPHQL_AUTH_USER_V2: 'Admin',
    NOGA_API_ENDPOINT: 'https://api2-stage.no-ga.com/api',
    NGLM_GRAPH_ENDPOINT: 'http://localhost:4501/nglm-graph/v2',
    NEXT_PUBLIC_SERVICE_ENDPOINT: 'http://localhost:4501/nglm-graph/v2',
};

const graphConfigDev: GraphConfig = {
    ...graphConfigCommon,
    GRAPHQL_AUTH_USER_V2: 'nglm-graph',
    NOGA_API_ENDPOINT: 'https://api2-stage.no-ga.com/api',
    NGLM_GRAPH_ENDPOINT: 'https://api2-stage.no-ga.com/nglm-graph/v2',
    NEXT_PUBLIC_SERVICE_ENDPOINT: 'https://api2-stage.no-ga.com/nglm-graph/v2',
};

const graphConfigStage: GraphConfig = {
    ...graphConfigCommon,
    GRAPHQL_AUTH_USER_V2: 'nglm-graph',
    NOGA_API_ENDPOINT: 'https://api2-stage.no-ga.com/api',
    NGLM_GRAPH_ENDPOINT: 'https://api2-stage.no-ga.com/nglm-graph/v2',
    NEXT_PUBLIC_SERVICE_ENDPOINT: 'https://api2-stage.no-ga.com/nglm-graph/v2',
};

const graphConfigProd: GraphConfig = {
    ...graphConfigCommon,
    GRAPHQL_AUTH_USER_V2: 'nglm-graph',
    NOGA_API_ENDPOINT: 'https://api2.no-ga.com/api',
    NGLM_GRAPH_ENDPOINT: 'https://api2.no-ga.com/nglm-graph/v2',
    NEXT_PUBLIC_SERVICE_ENDPOINT: 'https://api2.no-ga.com/nglm-graph/v2',
};

let graphConfig: GraphConfig;
switch (environment) {
    case EnvEnum.enum.local:
        graphConfig = graphConfigLocal;
        break;
    case EnvEnum.enum.dev:
        graphConfig = graphConfigDev;
        break;
    case EnvEnum.enum.stage:
        graphConfig = graphConfigStage;
        break;
    case EnvEnum.enum.preprod:
    case EnvEnum.enum.prod:
    default:
        graphConfig = graphConfigProd;
        break;
}

/////////////////////////// TESTFREAKS ///////////////////////////
type TestfreaksConfig = {
    NEXT_PUBLIC_SE_TESTFREAKS_CLIENT_ID: string;
    NEXT_PUBLIC_NO_TESTFREAKS_CLIENT_ID: string;
    NEXT_PUBLIC_DK_TESTFREAKS_CLIENT_ID: string;
    NEXT_PUBLIC_FI_TESTFREAKS_CLIENT_ID: string;
    NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID: string;
};

const testfreaksConfigStage: TestfreaksConfig = {
    NEXT_PUBLIC_SE_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.se-staging',
    NEXT_PUBLIC_NO_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.no-staging',
    NEXT_PUBLIC_DK_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.dk-staging',
    NEXT_PUBLIC_FI_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.fi-staging',
    NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID: 'no-ga.com-staging',
};

const testfreaksConfigProd: TestfreaksConfig = {
    NEXT_PUBLIC_SE_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.se',
    NEXT_PUBLIC_NO_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.no',
    NEXT_PUBLIC_DK_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.dk',
    NEXT_PUBLIC_FI_TESTFREAKS_CLIENT_ID: 'nordiskagalleriet.fi',
    NEXT_PUBLIC_EU_TESTFREAKS_CLIENT_ID: 'no-ga.com',
};

let testfreaksConfig: TestfreaksConfig;
switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
    case EnvEnum.enum.stage:
        testfreaksConfig = testfreaksConfigStage;
        break;
    case EnvEnum.enum.preprod:
    case EnvEnum.enum.prod:
    default:
        testfreaksConfig = testfreaksConfigProd;
        break;
}

/////////////////////////// KLARNA ///////////////////////////
type KlarnaConfig = {
    KLARNA_OSM_DATA_CLIENT_ID: string;
    KLARNA_OSM_DATA_ENVIRONMENT: string;
    NEXT_PUBLIC_KLARNA_PAYMENT_METHOD_ID: string;
    NEXT_PUBLIC_KLARNA_RETURN_URL: string;
};

const klarnaConfigCommon = {
    NEXT_PUBLIC_KLARNA_PAYMENT_METHOD_ID: '156',
    NEXT_PUBLIC_KLARNA_RETURN_URL: 'checkout/success?sid={checkout.order.id}',
} as const;

const klarnaConfigDev = {
    ...klarnaConfigCommon,
    KLARNA_OSM_DATA_CLIENT_ID: '4b896af2-3d77-59be-b4fd-33c5f15ccc36',
    KLARNA_OSM_DATA_ENVIRONMENT: 'playground',
} as const;

const klarnaConfigProd = {
    ...klarnaConfigCommon,
    KLARNA_OSM_DATA_CLIENT_ID: 'f7100c78-4dce-5aa9-9cd6-81e94eb8881d',
    KLARNA_OSM_DATA_ENVIRONMENT: 'production',
} as const;

let klarnaConfig: KlarnaConfig;
switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
    case EnvEnum.enum.stage:
        klarnaConfig = klarnaConfigDev;
        break;
    case EnvEnum.enum.preprod:
    case EnvEnum.enum.prod:
    default:
        klarnaConfig = klarnaConfigProd;
        break;
}

/////////////////////////// ADYEN ///////////////////////////
type AdyenConfig = {
    ADYEN_MERCHANT_ACCOUNT: string;
    NEXT_PUBLIC_ADYEN_CLIENT_KEY?: string;
    NEXT_PUBLIC_ADYEN_ENVIRONMENT?: 'test' | 'live';
    NEXT_PUBLIC_ADYEN_RETURN_URL: string;
    NEXT_PUBLIC_ADYEN_PAYMENT_METHOD_ID: string;
};

const adyenConfigCommon: AdyenConfig = {
    ADYEN_MERCHANT_ACCOUNT: 'NordiskaGallerietGroupABECOM',
    NEXT_PUBLIC_ADYEN_RETURN_URL: 'checkout/success?orderRef={ORDERREF}',
    NEXT_PUBLIC_ADYEN_PAYMENT_METHOD_ID: '14',
};

const adyenConfigTest: AdyenConfig = {
    ...adyenConfigCommon,
    NEXT_PUBLIC_ADYEN_CLIENT_KEY: 'test_S3ABJM274FGWVJ3QNPYZHICYMQADM3MV',
    NEXT_PUBLIC_ADYEN_ENVIRONMENT: 'test',
};

const adyenConfigLive: AdyenConfig = {
    ...adyenConfigCommon,
    NEXT_PUBLIC_ADYEN_CLIENT_KEY: 'live_L6JMMVBS5JGUVM6E45K3GCWUKI7IKMYC',
    NEXT_PUBLIC_ADYEN_ENVIRONMENT: 'live',
};

const adyenConfig: AdyenConfig = getAdyenConfig();

function getAdyenConfig() {
    switch (environment) {
        case EnvEnum.enum.local:
        case EnvEnum.enum.dev:
        case EnvEnum.enum.stage:
            return adyenConfigTest;
        case EnvEnum.enum.preprod:
        case EnvEnum.enum.prod:
        default:
            return adyenConfigLive;
    }
}

/////////////////////////// WALLEY ///////////////////////////

type WalleyConfig = {
    NEXT_PUBLIC_WALLEY_PAYMENT_METHOD_ID: string;
    NEXT_PUBLIC_WALLEY_RETURN_URL: string;
    NEXT_PUBLIC_WALLEY_ENVIRONMENT?: 'development' | 'production';
};

const walleyConfigCommon: WalleyConfig = {
    NEXT_PUBLIC_WALLEY_PAYMENT_METHOD_ID: '165',
    NEXT_PUBLIC_WALLEY_RETURN_URL:
        'checkout/success?reference={checkout.publictoken}',
};

const walleyConfigDev: WalleyConfig = {
    ...walleyConfigCommon,
    NEXT_PUBLIC_WALLEY_ENVIRONMENT: 'development',
};

const walleyConfigProd: WalleyConfig = {
    ...walleyConfigCommon,
    NEXT_PUBLIC_WALLEY_ENVIRONMENT: 'production',
};

const walleyConfig: WalleyConfig = getWalleyConfig();

function getWalleyConfig() {
    switch (environment) {
        case EnvEnum.enum.local:
        case EnvEnum.enum.dev:
        case EnvEnum.enum.stage:
            return walleyConfigDev;
        case EnvEnum.enum.preprod:
        case EnvEnum.enum.prod:
            return walleyConfigProd;
    }
}

/////////////////////////// FIREBASE ///////////////////////////
type FirebaseConfig = {
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: string;
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: string;
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: string;
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: string;
    NEXT_PUBLIC_FIREBASE_APP_ID: string;
};

const firebaseConfigDev: FirebaseConfig = {
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
        'nordiska-galleriet-group-dev.firebaseapp.com',
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: 'nordiska-galleriet-group-dev',
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
        'nordiska-galleriet-group-dev.appspot.com',
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: '1048732899833',
    NEXT_PUBLIC_FIREBASE_APP_ID: '1:1048732899833:web:704790540de340a6918528',
};

const firebaseConfigProd: FirebaseConfig = {
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: 'no-ga-576b2.firebaseapp.com',
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: 'no-ga-576b2',
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: 'no-ga-576b2.appspot.com',
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: '389739306215',
    NEXT_PUBLIC_FIREBASE_APP_ID: '1:389739306215:web:ea316a8f2f9e2667205642',
};

let firebaseConfig: FirebaseConfig;
switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
    case EnvEnum.enum.stage:
        firebaseConfig = firebaseConfigDev;
        break;
    case EnvEnum.enum.preprod:
    case EnvEnum.enum.prod:
    default:
        firebaseConfig = firebaseConfigProd;
        break;
}

/////////////////////////// NORCE ///////////////////////////
type NorceConfig = {
    NEXT_PUBLIC_NORCE_IMAGE_CDN: string;
};

const stageCdnUrl = 'https://noga.stage.cdn-norce.tech';
const prodCdnUrl = 'https://noga.cdn-norce.tech';
const norceConfigStage: NorceConfig = {
    NEXT_PUBLIC_NORCE_IMAGE_CDN: stageCdnUrl,
};

const norceConfigProd: NorceConfig = {
    NEXT_PUBLIC_NORCE_IMAGE_CDN: prodCdnUrl,
};

let norceConfig: NorceConfig;
switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
    case EnvEnum.enum.stage:
        norceConfig = norceConfigStage;
        break;
    case EnvEnum.enum.preprod:
    case EnvEnum.enum.prod:
    default:
        norceConfig = norceConfigProd;
        break;
}

/////////////////////////// GTM ///////////////////////////
type GtmConfig = {
    GTM_CONTAINER_ID?: string;
};

let gtmConfig: GtmConfig = {
    GTM_CONTAINER_ID: undefined,
};

switch (environment) {
    case EnvEnum.enum.prod:
        gtmConfig = {
            GTM_CONTAINER_ID: 'GTM-TQMP4JN',
        };
        break;
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
    case EnvEnum.enum.stage:
    case EnvEnum.enum.preprod:
    default:
        break;
}

/////////////////////////// VOYADO ///////////////////////////
type VoyadoConfig = {
    VOYADO_SCRIPT_BUNDLE?: string;
};

let voyadoConfig: VoyadoConfig = {
    VOYADO_SCRIPT_BUNDLE: undefined,
};

switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
        break;
    case EnvEnum.enum.stage:
    case EnvEnum.enum.preprod:
        voyadoConfig = {
            VOYADO_SCRIPT_BUNDLE:
                'https://assets.voyado.com/jsfiles/analytics_0.1.7.staging.min.js',
        };
        break;
    case EnvEnum.enum.prod:
        voyadoConfig = {
            VOYADO_SCRIPT_BUNDLE:
                'https://assets.voyado.com/jsfiles/analytics_0.1.7.min.js',
        };
        break;
    default:
        break;
}

/////////////////////////// NSHIFT ///////////////////////////
type NShiftConfig = {
    NSHIFT_API_KEY_ID: string;
    NSHIFT_API_URL: string;
    NSHIFT_DELIVERY_CHECKOUT_ID: string;
    NSHIFT_DYNAMIC_FREIGHT_PARTNO: string;
    NEXT_PUBLIC_NSHIFT_DELIVERY_CHECKOUT_ID: string;
    NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO: string;
};

const nshiftConfigCommon = {
    NSHIFT_API_KEY_ID: '4ZDVGM27XVF7HHMF',
    NSHIFT_API_URL: 'https://api.unifaun.com/rs-extapi/v1',
    NSHIFT_DELIVERY_CHECKOUT_ID: '598bcef3-a83a-40ff-bc56-3aeab5c18e91',
    NEXT_PUBLIC_NSHIFT_DELIVERY_CHECKOUT_ID:
        '598bcef3-a83a-40ff-bc56-3aeab5c18e91',
};

let freightPartNo: string;
switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
        freightPartNo = '1000027';
        break;
    case EnvEnum.enum.stage:
    case EnvEnum.enum.preprod:
    case EnvEnum.enum.prod:
    default:
        freightPartNo = '1000000';
        break;
}

const nshiftConfig: NShiftConfig = {
    ...nshiftConfigCommon,
    NSHIFT_DYNAMIC_FREIGHT_PARTNO: freightPartNo,
    NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO: freightPartNo,
};

/////////////////////////// SITEMAPS ///////////////////////////
type SitemapConfig = {
    APP_BASE_DOMAIN: string;
    BUILD_APP_SEO: string;
    SITEMAP_NAME: string;
    SITEMAP_BLOB_STORAGE: string;
    SITEMAP_STORAGE_GUID: string;
    /**@deprecated we should move to using market-configurations.ts */
    APP_MARKETS_LOCALES: string;
    SITEMAP_APP_ENVIRONMENT: string; // TODO: remove. Only used for error logging.
    SITEMAP_BASE_DOMAIN: string;
};

const sitemapConfigCommon = {
    BUILD_APP_SEO: 'true',
    SITEMAP_BLOB_STORAGE: 'nggfeedpublicprod',
    SITEMAP_STORAGE_GUID: '4f11811e-3cbd-4b38-a9bb-634e5f1dffb5',
};

let sitemapName: string;
switch (environment) {
    case EnvEnum.enum.local:
        sitemapName = 'localhost';
        break;
    case EnvEnum.enum.preprod:
        sitemapName = 'preprod';
        break;
    case EnvEnum.enum.dev:
    case EnvEnum.enum.stage:
    case EnvEnum.enum.prod:
    default:
        sitemapName = 'noga';
        break;
}

let appBaseDomain: string;
let sitemapBaseDomain: string;
let appMarketsLocales: string;
switch (site) {
    case MarketEnum.enum.se:
        appBaseDomain = 'www.nordiskagalleriet.se';
        sitemapBaseDomain = 'nordiska';
        appMarketsLocales = '';
        break;
    case MarketEnum.enum.no:
        appBaseDomain = 'www.nordiskagalleriet.no';
        sitemapBaseDomain = 'nordiska';
        appMarketsLocales = '';
        break;
    case MarketEnum.enum.de:
    default:
        appBaseDomain = 'www.no-ga.com';
        sitemapBaseDomain = '';
        appMarketsLocales =
            'markets=en:de-es-at-fr-be-eu,da:dk,fi:fi,nl:nl&locales=en:eu,da:dk,fi:fi,nl:nl';
        break;
}

const sitemapConfig: SitemapConfig = {
    ...sitemapConfigCommon,
    SITEMAP_APP_ENVIRONMENT: environment,
    SITEMAP_NAME: sitemapName,
    APP_BASE_DOMAIN: appBaseDomain,
    SITEMAP_BASE_DOMAIN: sitemapBaseDomain,
    APP_MARKETS_LOCALES: appMarketsLocales,
};

/////////////////////////// SEARCH ENGINE ///////////////////////////
type SearchEngineConfig = {
    NEXT_PUBLIC_SEARCH_ENGINE_URL_SE: string;
    NEXT_PUBLIC_SEARCH_ENGINE_URL_NO: string;
    NEXT_PUBLIC_SEARCH_ENGINE_URL_DK: string;
    NEXT_PUBLIC_SEARCH_ENGINE_URL_FI: string;
    NEXT_PUBLIC_SEARCH_ENGINE_URL_NL: string;
    NEXT_PUBLIC_SEARCH_ENGINE_URL: string;
};

const searchEngineConfigDevCommon = {
    NEXT_PUBLIC_SEARCH_ENGINE_URL_SE:
        'https://nordiskagalleriet-se-dev-new.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_NO:
        'https://nordiskagalleriet-no-dev-new.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_DK:
        'https://nordiskagalleriet-dk-dev-new.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_FI:
        'https://nordiskagalleriet-fi-dev-new.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_NL:
        'https://nordiskagalleriet-nl-dev-new.54proxy.com',
};

const searchEngineConfigProdCommon = {
    NEXT_PUBLIC_SEARCH_ENGINE_URL_SE:
        'https://nordiskagalleriet-se-prod.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_NO:
        'https://nordiskagalleriet-no-prod.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_DK:
        'https://nordiskagalleriet-dk-prod.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_FI:
        'https://nordiskagalleriet-fi-prod.54proxy.com',
    NEXT_PUBLIC_SEARCH_ENGINE_URL_NL:
        'https://nordiskagalleriet-nl-prod.54proxy.com',
};

let searchEngineUrl: string;
switch (site) {
    case MarketEnum.enum.se:
        searchEngineUrl =
            searchEngineConfigProdCommon.NEXT_PUBLIC_SEARCH_ENGINE_URL_SE;
        break;
    case MarketEnum.enum.no:
        searchEngineUrl =
            searchEngineConfigProdCommon.NEXT_PUBLIC_SEARCH_ENGINE_URL_NO;
        break;
    case MarketEnum.enum.de:
    default:
        searchEngineUrl = 'https://nordiskagalleriet-eu-prod.54proxy.com';
        break;
}

let searchEngineConfig: SearchEngineConfig;
switch (environment) {
    case EnvEnum.enum.local:
    case EnvEnum.enum.dev:
        searchEngineConfig = {
            ...searchEngineConfigDevCommon,
            NEXT_PUBLIC_SEARCH_ENGINE_URL: searchEngineUrl,
        };
        break;
    default:
        searchEngineConfig = {
            ...searchEngineConfigProdCommon,
            NEXT_PUBLIC_SEARCH_ENGINE_URL: searchEngineUrl,
        };
}

/////////////////////////// GENERAL ///////////////////////////
type GeneralConfig = {
    NEXT_PUBLIC_BRAND: string;
    NEXT_PUBLIC_APP_MARKET: string;
    NEXT_PUBLIC_APP_BASE_URL: string;
    NEXT_PUBLIC_GIFT_CARD_PAYMENT_METHOD_ID: string;
    NEXT_PUBLIC_APP_LANGUAGE: string;
    NEXT_PUBLIC_IMGIX_DOMAIN: string;
};

const generalConfigCommon = {
    NEXT_PUBLIC_BRAND: z.string().parse(process.env.NEXT_PUBLIC_BRAND),
    NEXT_PUBLIC_APP_MARKET: z
        .string()
        .parse(process.env.NEXT_PUBLIC_APP_MARKET),
    NEXT_PUBLIC_APP_BASE_URL: z
        .string()
        .parse(process.env.NEXT_PUBLIC_APP_BASE_URL),
    NEXT_PUBLIC_GIFT_CARD_PAYMENT_METHOD_ID: '87',
    NEXT_PUBLIC_IMGIX_DOMAIN: 'https://dev-nordiskagalleriet.imgix.net',
};

let appLanguage: string;
switch (site) {
    case MarketEnum.enum.se:
        appLanguage = 'sv';
        break;
    case MarketEnum.enum.no:
        appLanguage = 'nb';
        break;
    case MarketEnum.enum.de:
    default:
        appLanguage = 'en';
        break;
}

const generalConfig: GeneralConfig = {
    ...generalConfigCommon,
    NEXT_PUBLIC_APP_LANGUAGE: appLanguage,
};

/////////////////////////// EU FLAGS ///////////////////////////
type EuFlagsConfig = {
    HREFLANG_ENABLED: string;
    NEXT_PUBLIC_DK: string;
    NEXT_PUBLIC_FEATURE_SITEMAP: string;
    NEXT_PUBLIC_FI: string;
    NEXT_PUBLIC_NL: string;
    NEXT_PUBLIC_NL_COMING_SOON_PAGE: string;
};

const EuFlagsConfigDefault: EuFlagsConfig = {
    HREFLANG_ENABLED: 'false',
    NEXT_PUBLIC_DK: 'false',
    NEXT_PUBLIC_FEATURE_SITEMAP: 'false',
    NEXT_PUBLIC_FI: 'false',
    NEXT_PUBLIC_NL: 'false',
    NEXT_PUBLIC_NL_COMING_SOON_PAGE: 'false',
};

const EuFlagsConfigEu: EuFlagsConfig = {
    HREFLANG_ENABLED: 'true',
    NEXT_PUBLIC_DK: 'true',
    NEXT_PUBLIC_FEATURE_SITEMAP: 'true',
    NEXT_PUBLIC_FI: 'true',
    NEXT_PUBLIC_NL: 'true',
    NEXT_PUBLIC_NL_COMING_SOON_PAGE: 'true',
};

const euFlagsConfig =
    site === MarketEnum.enum.de ? EuFlagsConfigEu : EuFlagsConfigDefault;

/////////////////////////// ASSEMBLY ///////////////////////////
export type EnvironmentConfig = ContentfulConfig &
    DatadogConfig &
    FeatureFlagConfig &
    CacheConfig &
    GraphConfig &
    TestfreaksConfig &
    AdyenConfig &
    KlarnaConfig &
    WalleyConfig &
    FirebaseConfig &
    NorceConfig &
    GtmConfig &
    VoyadoConfig &
    NShiftConfig &
    SitemapConfig &
    SearchEngineConfig &
    GeneralConfig &
    EuFlagsConfig;

export const envConfig: EnvironmentConfig = {
    ...contentfulConfig,
    ...datadogConfig,
    ...featureFlagConfig,
    ...cacheConfig,
    ...graphConfig,
    ...testfreaksConfig,
    ...adyenConfig,
    ...klarnaConfig,
    ...walleyConfig,
    ...firebaseConfig,
    ...norceConfig,
    ...gtmConfig,
    ...voyadoConfig,
    ...nshiftConfig,
    ...sitemapConfig,
    ...searchEngineConfig,
    ...generalConfig,
    ...euFlagsConfig,
};

export const SUPPORTED_LOCALES = [
    'en-de',
    'en-be',
    'en-nl',
    'en-fr',
    'en-es',
    'en-at',
    'eu',
    ...(envConfig.NEXT_PUBLIC_FI === 'true' ? ['fi'] : []),
    ...(process.env.NEXT_PUBLIC_EN_FI === 'true' ? ['en-fi'] : []),
    ...(envConfig.NEXT_PUBLIC_DK === 'true' ? ['dk'] : []),
    ...(process.env.NEXT_PUBLIC_EN_DK === 'true' ? ['en-dk'] : []),
    ...(process.env.NEXT_PUBLIC_EN_SE === 'true' ? ['en-se'] : []),
    ...(process.env.NEXT_PUBLIC_EN_NO === 'true' ? ['en-no'] : []),
    ...(envConfig.NEXT_PUBLIC_NL === 'true' ? ['nl'] : []),
];
