import type { SlugsQuery } from '@server/gql/graphql';

import type { MarketConfiguration } from '@/config/market-configurations';

export const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

export const SITE_BRAND = 'NG';

export const SEARCH_ENGINE_USER = 'ng-search';

export const TITLE_SITE_NAME = 'NO GA';
export const TITLE_DELIMITER = '|';
export const TITLE_PAGE_NUMBER_PREFIX = 'Page';
export const TITLE_PAGE_NUMBER_DELIMITER = '-';

export const CATEGORY_PAGE_DICTIONARY_ID = '26pPduK6BYLwGYoCshTMlt';

export const RECOMMENDED_PRICE_TEXT_BLOCK_NAME = 'Recommended price';

/**
 * Breadcrumbs.Code will only search for products for which the category is the primary/breadcrumbs category.
 * Category will search for products for which the category is the primary/breadcrumbs category or one of the additional categories.
 */
type CategoryAttribute = 'Category' | 'Breadcrumbs.Code';
type StatusAttribute = 'statuses';

export const RANGE_FILTER_ATTRIBUTES = [
    'Price',
    'depth_cm',
    'height_cm',
    'length_cm',
    'width_cm',
    'diameter_cm',
] as const;

export const DEFAULT_CATEGORY_ATTRIBUTE: CategoryAttribute = 'Category';
export const DEFAULT_STATUSES_ATTRIBUTE: StatusAttribute = 'statuses';

export type MarketAwareSlug = {
    marketConfig: MarketConfiguration;
    slug: string;
    cleanSlug: string;
    parts: string[];
    pageNumber: number;
};

export const ANONYMOUS_WISHLIST_TOKEN_NAME = 'Anonymous-Wishlist-Token';

export const ANONYMOUS_WISHLIST_ID_NAME = 'Anonymous-Wishlist-Id';

export const getIsoLocale = ({
    market,
    languageCode,
}: {
    market: string;
    languageCode: string;
}) => `${languageCode.toLowerCase()}-${market.toUpperCase()}`;

/** @deprecated This should not be used */
export const getNewCustomerUrl = (locale?: string) => {
    switch (locale) {
        case 'sv-se':
            return '/ny-kund';
        case 'dk':
            return '/dk/nykunde';
        case 'fi':
            return '/fi/uusasiakas';
        case 'nb-no':
            return '/nykunde-2';
        default:
            return `/${locale}/new-customer`;
    }
};

export const isValidSlug = (allSlugs: SlugsQuery, slug: string) => {
    const slugs = [
        ...(allSlugs.pages?.items ?? []),
        ...(allSlugs.articles?.items ?? []),
        ...(allSlugs.campaigns?.items ?? []),
        ...(allSlugs.landingPages?.items ?? []),
    ];
    return slugs
        .map((item) => item?.slug)
        .filter(Boolean)
        .includes(slug);
};
