import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type ReactNode } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

import { LoadingIcon } from '@/icons/LoadingIcon';
import { cn } from '@/lib/utils';

const buttonVariants = tv(
    {
        base: 'inline-flex items-center justify-center whitespace-nowrap text-center tracking-wide transition-colors disabled:pointer-events-none disabled:opacity-50 max-md:text-sm',
        variants: {
            variant: {
                primary: 'bg-black text-white',
                secondary: 'bg-gray-200 hover:bg-gray-300',
                outline: 'border border-current',
                ghost: 'bg-transparent',
                link: 'underline underline-offset-2',
            },
            size: {
                sm: 'h-9 px-3 text-sm',
                md: 'h-10 px-4',
                lg: 'h-11 px-6',
                xl: 'h-12 px-8',
                '2xl': 'h-14 text-lg',
                icon: 'h-10 w-10',
            },
        },
    },
    {
        responsiveVariants: true,
    },
);

export type ButtonProps = React.ComponentPropsWithRef<'button'> &
    VariantProps<typeof buttonVariants> & {
        asChild?: boolean;
        isLoading?: boolean;
        loadingIcon?: ReactNode;
        iconSize?: number;
        as?: React.ElementType | null
        href?: string;
    };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            className,
            variant,
            size,
            asChild = false,
            isLoading = false,
            iconSize = 16,
            href = "",
            as = null,
            ...props
        },
        ref,
    ) => {
        if (as) {
            const Component = as;

            return (
                <Component
                    href={href}
                    className={cn(buttonVariants({ variant, size, className }))}
                    ref={ref}
                    {...props}>
                    {children}
                </Component>
            );
        }

        const Comp = asChild ? Slot : 'button';

        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...props}>
                {isLoading ? (
                    <LoadingIcon className="animate-spin" size={iconSize} />
                ) : (
                    children
                )}
            </Comp>
        );
    },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
