'use client';

import Badge from '@components/Badge';
import Button from '@components/Button';
import Flag from '@components/Flag';
import { Heading, Paragraph } from '@components/ui';
import { useMarket } from '@context/marketContext';
import type { DialogProps } from '@ngg/components';
import { Dialog } from '@ngg/components';
import { CheckIcon, XIcon } from '@ngg/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import type { Dictionary } from 'types';

import {
    getLink,
    getMarketConfigsSortedByLabel,
    type MarketConfiguration,
} from '@/config/market-configurations';
import { cn } from '@/lib/utils';

export default function MarketSelector({
    classNames,
    dictionary,
}: Pick<DialogProps, 'classNames'> & { dictionary: Dictionary }) {
    const { state, onSave, onClose } = useMarket();
    const queryClient = useQueryClient();
    const { data: open } = useQuery({
        queryKey: ['marketSelector'],
        queryFn: ({ queryKey }) => queryClient.fetchQuery({ queryKey }),
        initialData: Boolean(state.suggested) && !state.dismissed,
    });
    const [config, setConfig] = useState<MarketConfiguration | undefined>();

    useEffect(() => {
        if (state.suggested) {
            setConfig(state.suggested);
            return;
        }

        setConfig(state.market);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (e: MouseEvent, market: MarketConfiguration) => {
        e.preventDefault();
        setConfig(market);
    };

    if (!config) return null;

    return (
        <Dialog
            onClose={onClose}
            title={undefined}
            open={open}
            classNames={classNames}>
            <div className="flex flex-col items-start p-6 text-start space-y-6">
                {state.suggested ? (
                    <div>
                        <Heading as="h1" size={5} className="mb-4 font-medium">
                            {dictionary.browsingFromLocation?.replace(
                                '%s',
                                state.suggested.label,
                            )}
                        </Heading>
                        <Paragraph size="small">
                            {dictionary.browseOurLocalizedVersion?.replace(
                                '%s',
                                state.suggested.label,
                            )}
                        </Paragraph>
                    </div>
                ) : (
                    <>
                        <button
                            data-testid="market-selector-close-button"
                            onClick={onClose}
                            className="absolute right-0 top-0 p-3"
                            type="button">
                            <XIcon />
                        </button>
                        <div>
                            <Heading
                                as="h1"
                                size={5}
                                className="mb-4 font-medium">
                                {dictionary.welcome}
                            </Heading>
                            <Paragraph size="small">
                                {dictionary.pleaseChooseCountry}
                                <br />
                                {dictionary.countryNotListed}
                            </Paragraph>
                        </div>
                    </>
                )}
                <ul className="grid grid-cols-4 justify-items-center gap-4 md:grid-cols-5">
                    {getMarketConfigsSortedByLabel().map((market) => (
                        <li
                            key={market.code}
                            className={cn(
                                'flex flex-col items-center text-xs',
                                config.code !== market.code && 'opacity-50',
                            )}>
                            <button
                                type="button"
                                onClick={(e) => onChange(e, market)}
                                className="contents space-y-1">
                                <Badge
                                    badgeContent={
                                        config.code === market.code ? (
                                            <CheckIcon />
                                        ) : null
                                    }
                                    className="bg-success text-white">
                                    <Flag
                                        code={market.code}
                                        className="h-10 w-10 rounded-full object-cover"
                                    />
                                </Badge>
                                <span className="font-medium">
                                    {market.label}
                                </span>
                                <span>({market.language.label})</span>
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="flex w-full flex-col space-y-2">
                    <Button
                        as={Button}
                        className="btn-primary w-full"
                        onClick={() => {
                            if (config.locale) {
                                onSave({
                                    market: config.code,
                                });
                            } else {
                                onSave({
                                    link: getLink(state.market, config),
                                });
                            }
                            queryClient.setQueryData(['marketSelector'], false);
                        }}>
                        {dictionary.continueToMarket} {config.label}
                    </Button>
                    <div className="flex items-center space-x-1">
                        <span>{dictionary.currency}:</span>
                        <span className="font-medium">{config.currency}</span>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
