'use client';

import { useMarket } from '@context/marketContext';
import { useSiteInfo } from '@context/siteInfoContext';
import type { Page as PageData } from '@server/gql/graphql';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { Product, Variant } from 'types';

import { ProductCardContext } from './ProductCardContext';

type ProductCardRootProps = {
    children?: ReactNode;
    product?: Product | Variant;
    pageData?: PageData;
    hideHoverImage?: boolean;
    hideStock?: boolean;
    isDense?: boolean;
    isEager?: boolean;
    onRemove?: () => void;
};

const ProductCardRoot = ({
    children,
    product,
    pageData,
    hideHoverImage,
    hideStock,
    isDense,
    isEager,
    onRemove,
}: ProductCardRootProps) => {
    const { globalDictionary } = useSiteInfo();
    const marketConfig = useMarket().state.market;

    const [primaryImage, hoverImage] = product?.images ?? [
        product?.ImageURL
            ? {
                  src: product.ImageURL,
                  type: 'image',
                  id: product.id,
                  alt: product.title,
              }
            : undefined,
    ];
    const [hoverImageHasLoaded, setHoverImageHasLoaded] = useState(false);

    const productCardContextValue = useMemo(
        () => ({
            marketConfig,
            locale: marketConfig.locale,
            globalDictionary,
            pageData,
            product,
            primaryImage,
            hoverImage,
            hoverImageHasLoaded,
            setHoverImageHasLoaded,
            isDense,
            hideHoverImage,
            hideStock,
            isEager,
            onRemove,
        }),
        [
            marketConfig,
            globalDictionary,
            pageData,
            product,
            primaryImage,
            hoverImage,
            hoverImageHasLoaded,
            setHoverImageHasLoaded,
            isDense,
            hideHoverImage,
            hideStock,
            isEager,
            onRemove,
        ],
    );

    if (!product) return null;

    return (
        <ProductCardContext.Provider value={productCardContextValue}>
            {children}
        </ProductCardContext.Provider>
    );
};

ProductCardRoot.displayName = 'ProductCardRoot';

export default ProductCardRoot;
