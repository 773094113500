'use client';

import type { Page as PageData } from '@server/gql/graphql';
import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { Dictionary, Image, Product, Variant } from 'types';

import type { MarketConfiguration } from '@/config/market-configurations';

export type ProductCardContextProps = {
    marketConfig?: MarketConfiguration;
    locale?: string;
    product?: Product | Variant;
    pageData?: PageData;
    globalDictionary?: Dictionary;
    hideHoverImage?: boolean;
    hideStock?: boolean;
    isDense?: boolean;
    primaryImage?: Image;
    hoverImage?: Image;
    hoverImageHasLoaded?: boolean;
    setHoverImageHasLoaded?: Dispatch<SetStateAction<boolean>>;
    onRemove?: () => void;
    isEager?: boolean;
};

export const ProductCardContext = createContext<ProductCardContextProps>({
    globalDictionary: {},
});
