'use client';

import { LazyRichText } from '@app/_blocks/RichText/LazyRichText';
import { ContentfulImage } from '@app/_components/ContentfulImage';
import Link from '@app/_components/Link';
import { Heading } from '@app/_components/ui';
import type {
    ContentfulAssetFragment,
    FooterBannerFragment,
} from '@server/gql/graphql';

type Props = {
    locale?: string;
    market: string;
    footerBanner?: FooterBannerFragment | null;
};

export const FooterBannerContent = (props: Props) => {
    const { footerBanner, market, locale } = props;

    if (!footerBanner) {
        return null;
    }

    const { title, paragraph, link, linkText, desktopImage, mobileImage } =
        footerBanner;
    const linkSlug = link?.slug;

    return (
        <section className="flex grid-cols-2 flex-row items-stretch bg-black text-white max-md:flex-col md:grid">
            <div className="flex flex-col items-start justify-center gap-6 px-4 py-8 max-md:text-sm md:p-[6vw] lg:p-[8vw]">
                <div className="flex flex-col gap-3">
                    {title && (
                        <Heading as="h1" size={5}>
                            {title}
                        </Heading>
                    )}
                    {paragraph && (
                        <LazyRichText
                            content={paragraph}
                            locale={locale}
                            market={market}
                            className="text-white"
                        />
                    )}
                </div>
                {linkSlug && linkText && (
                    <Link
                        href={linkSlug}
                        className="min-w-60 border border-white px-4 py-2 text-center max-md:w-full"
                        locale={locale}>
                        {linkText}
                    </Link>
                )}
            </div>
            <ContentfulImage
                mobileImage={mobileImage as ContentfulAssetFragment}
                desktopImage={desktopImage as ContentfulAssetFragment}
                className="relative aspect-[13/8] h-full w-full object-cover max-md:-order-1 md:aspect-square lg:aspect-[9/4]"
                sizes="(max-width: 768px) 100vw, 50vw"
                loading="lazy"
            />
        </section>
    );
};
