'use client';

import Flag from '@components/Flag';
import type { Market } from '@context/common';
import { useMarket } from '@context/marketContext';
import { Listbox } from '@headlessui/react';
import { ArrowDownIcon } from '@ngg/icons';
import { useCallback } from 'react';

import {
    getLink,
    getMarketConfigFromCode,
    getMarketConfigsSortedByLabel,
} from '@/config/market-configurations';
import { cn } from '@/lib/utils';

import MarketSelectorOption from './MarketSelectorOption';

export default function MarketSelectorDropdown({
    classNames,
    disabled = false,
}: {
    filter?: (market: Market) => boolean;
    disabled?: boolean;
    classNames?: {
        wrapper?: string;
        list?: string;
        input?: string;
    };
}) {
    const { state, onSave } = useMarket();

    const value = state.market;

    const markets = getMarketConfigsSortedByLabel();

    const onChange = useCallback(
        (payload: Market) => {
            if (!payload) {
                return;
            }

            const link = getLink(
                state.market,
                getMarketConfigFromCode(payload.code),
            );
            onSave({ link: link });
        },
        [onSave, state.market],
    );

    return (
        <Listbox
            onChange={onChange}
            by="code"
            as="div"
            disabled={disabled}
            className={classNames?.wrapper || 'relative z-50 h-full'}>
            <Listbox.Options
                className={cn([
                    'absolute right-0 top-full w-full min-w-80 -translate-y-px divide-y border bg-white text-sm',
                    classNames?.list,
                ])}>
                {markets.map((market) => {
                    return (
                        <MarketSelectorOption
                            key={market.code}
                            as={Listbox.Option}
                            market={market}
                            value={market}
                            className="ui-selected:bg-gray-100 ui-active:bg-gray-100"
                        />
                    );
                })}
            </Listbox.Options>
            <Listbox.Button
                className={cn(
                    classNames?.input ||
                        'relative flex h-full items-center border-x border-t bg-transparent bg-white px-2 uppercase space-x-2 ui-not-open:border-transparent ui-not-open:bg-transparent',
                    {
                        'disabled:cursor-not-allowed disabled:opacity-50':
                            disabled,
                    },
                )}>
                <Flag
                    code={value.code}
                    className="h-3 w-3 rounded-full border border-black object-cover"
                />
                <span className="flex-1 text-left">{value.code}</span>
                <ArrowDownIcon />
            </Listbox.Button>
        </Listbox>
    );
}
